/* 分割组件需要样式 begin*/
.Resizer {
    background: #000;
    opacity: 0.2;
    z-index: 9999;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;
}

.Resizer:hover {
    -webkit-transition: all 2s ease;
    transition: all 2s ease;
}

.Resizer.horizontal {
    height: 11px;
    margin: -5px 0;
    border-top: 5px solid rgba(255, 255, 255, 0);
    border-bottom: 5px solid rgba(255, 255, 255, 0);
    cursor: row-resize;
    width: 100%;
}

.Resizer.horizontal:hover {
    border-top: 5px solid rgba(0, 0, 0, 0.5);
    border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
    width: 11px;
    margin: 0 -5px;
    border-left: 5px solid rgba(255, 255, 255, 0);
    border-right: 5px solid rgba(255, 255, 255, 0);
    cursor: col-resize;
}

.Resizer.vertical:hover {
    border-left: 5px solid rgba(0, 0, 0, 0.5);
    border-right: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.disabled {
    cursor: not-allowed;
}
.Resizer.disabled:hover {
    border-color: transparent;
}
/* 分割组件需要样式 end*/

.logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
    background: #fff;
}
[data-theme="dark"] .site-layout .site-layout-background {
    background: #141414;
}
body {
    background-color: #1e1e1e;
}
.ant-layout-sider,
.ant-layout-sider-trigger,
.ant-menu,
.ant-menu-item {
    background: #242426 !important;
}
.site-tree-search-value {
    color: #f50;
}
[data-theme="dark"] .site-tree-search-value {
    color: #d84a1b;
}
.ant-tabs-nav {
    min-width: 30px !important;
}
.ant-tabs-tab {
    margin: 0 !important;
    padding-left: 7px !important;
    width: 30px;
}
.ant-tabs-tabpane {
    padding-left: 0 !important;
}
.ant-collapse,
.ant-collapse-item {
    border: 0 !important;
}
.ant-collapse-header {
    background: #242426;
    color: #ffffff !important;
    border: 0 !important;
}
.ant-collapse-content {
    background: #242426;
    color: #ffffff !important;
    border: 1px solid #ccc !important;
}
/* .ant-tree {
    height: 20% !important;
    overflow: auto !important;
} */
.ant-tabs-content-holder {
    height: 100% !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
}
.ant-layout-sider-trigger {
    position: relative;
}
.ant-menu-submenu-title {
    padding-left: 10px !important;
}
.ant-popconfirm {
    z-index: 9999;
}
